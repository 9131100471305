define(["handlebars"], function(Handlebars) { return Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n<div class=\"cf\"><a class=\"btn btn-primary close-fb\" href=\"#\">";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.props)),stack1 == null || stack1 === false ? stack1 : stack1.ajaxBasket)),stack1 == null || stack1 === false ? stack1 : stack1.titles)),stack1 == null || stack1 === false ? stack1 : stack1.basketContinue), options) : helperMissing.call(depth0, "rawHTML", ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.props)),stack1 == null || stack1 === false ? stack1 : stack1.ajaxBasket)),stack1 == null || stack1 === false ? stack1 : stack1.titles)),stack1 == null || stack1 === false ? stack1 : stack1.basketContinue), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</a></div>\n";
  return buffer;
  }

  buffer += "<p class=\"bp-feedback-details\">";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.errorMessage), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.errorMessage), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</p>\n";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.showContinueBtn), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }); });